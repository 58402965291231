import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'

const IndexPage = () => (
  <>
    <SEO title="Terms" />
    <article className="basicPageContainer">
      <h1>Terms</h1>
      <div className="info">
        <h2 dir="ltr">Welcome</h2>

        <p dir="ltr">
          Thanks for using our products and services (“Services” or “Service”). The
          Services are provided by Redfin Solutions, a Maine Limited Liability Company
          (“Redfin”, "us," "we," or "our,"), located at 619 Brighton Ave., Portland, ME
          04102, United States.
        </p>

        <p dir="ltr">
          We may, from time to time, change our Terms of Service (“Terms”), so please
          check back regularly for any changes or updates. If you have any questions
          regarding these Terms, contact us at{' '}
          <a
            href="mailto:legal@redfinsolutions.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            legal@redfinsolutions.com
          </a>
          .
        </p>

        <p dir="ltr">
          By using our Services, you are agreeing to these terms. If you disagree with any
          part of the terms then you do not have permission to access the Service. Please
          read them carefully.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Using our Services</h2>

        <p dir="ltr">
          You must follow any policies made available to you within the Services.
        </p>

        <p dir="ltr">
          Don’t misuse our Services. For example, don’t interfere with our Services or try
          to access them using a method other than the interface and the instructions that
          we provide. You may use our Services only as permitted by law, including
          applicable export and re-export control laws and regulations. We may suspend or
          stop providing our Services to you if you do not comply with our terms or
          policies or if we are investigating suspected misconduct.
        </p>

        <p dir="ltr">
          Using our Services does not give you ownership of any intellectual property
          rights in our Services or the content you access. You may not use content from
          our Services unless you obtain permission from its owner or are otherwise
          permitted by law. These terms do not grant you the right to use any branding or
          logos used in our Services. Don’t remove, obscure, or alter any legal notices
          displayed in or along with our Services.
        </p>

        <p dir="ltr">
          Our Services display some content that is not Redfin’s. This content is the sole
          responsibility of the entity that makes it available. We may review content to
          determine whether it is illegal or violates our policies, and we may remove or
          refuse to display content that we reasonably believe violates our policies or
          the law. But that does not necessarily mean that we review content, so please
          don’t assume that we do.
        </p>

        <p dir="ltr">
          In connection with your use of the Services, we may send you service
          announcements, administrative messages, and other information. You may opt out
          of some of those communications.
        </p>

        <p dir="ltr">
          Some of our Services are available on mobile devices. Do not use such Services
          in a way that distracts you and prevents you from obeying traffic or safety
          laws.
        </p>

        <p dir="ltr">
          These terms control the relationship between Redfin and you. They do not create
          any third party beneficiary rights.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Your Redfin Account</h2>

        <p dir="ltr">
          You may need a Redfin Account in order to use some of our Services. You may
          create your own Redfin Account, or your Redfin Account may be assigned to you by
          Redfin when you elect to engage in our Services. For example, you may be
          assigned a Redfin Account to help you manage what time is being spent on work
          being done for you or your company and manage your newsletter and other
          preferences.
        </p>

        <p dir="ltr">
          To protect your Redfin Account, keep your password confidential. You are
          responsible for the activity that happens on or through your Redfin Account. Try
          not to reuse your Redfin Account password on third-party applications. If you
          learn of any unauthorized use of your password or Redfin Account, please contact
          us immediately by calling 908-437-8725.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Privacy and Copyright Protection</h2>

        <p dir="ltr">
          Redfin’s <Link to="/privacy">Privacy Policy</Link> explains how we treat your
          personal data and protect your privacy when you use our Services. By using our
          Services, you agree that we can use such data in accordance with our privacy
          policies. Be advised, however, that the security of the Internet cannot be
          guaranteed and therefore Redfin is not responsible for any unauthorized access
          to communications that you submit over the Internet. We will never send an email
          asking you for passwords, credit card information or any similar private
          information.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Employment</h2>

        <p dir="ltr">
          Redfin may post job openings from time to time on our website but these are not
          our only means of hiring. Redfin is an equal opportunity employer – we provide
          equal employment opportunity to qualified persons without regard to race, color,
          religion, sex, national origin, age, veteran status, or disability.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Modifying and Terminating our Services</h2>

        <p dir="ltr">
          We are constantly changing and improving our Services. We may add or remove
          functionalities or features, and we may suspend or stop a Service altogether.
          You can stop using our Services at any time. Redfin may also stop providing
          Services to you, or add or create new limits to our Services at any time. We
          believe that you own your data and preserving your access to such data is
          important. If we discontinue a Service, where reasonably possible, we will give
          you reasonable advance notice and a chance to get information out of that
          Service.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Links</h2>

        <p dir="ltr">
          Redfin may, from time to time, provide links to other outside websites. Redfin
          is not responsible for such linked websites or the content of any of the linked
          websites.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Copyright</h2>

        <p dir="ltr">
          All content included on this site is the property of Redfin, and is protected by
          copyright law. We retain all rights, titles and interest in this website's
          content. Redfin grants you the nonexclusive, nontransferable, limited, and
          revocable right to print individual pages from this website, without alteration,
          for your own personal use. Re-use of any portion of our written or graphical
          content without our express written consent is strictly prohibited by Redfin.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Disclaimer</h2>

        <p dir="ltr">
          Your use of the Service is at your sole risk. The Service is provided on an "AS
          IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any
          kind, whether express or implied, including, but not limited to, implied
          warranties of merchantability, fitness for a particular purpose,
          non-infringement or course of performance. Redfin, its subsidiaries, affiliates,
          and its licensors do not warrant that a) the Service will function
          uninterrupted, secure or available at any particular time or location; b) any
          errors or defects will be corrected; c) the Service is free of viruses or other
          harmful components; or d) the results of using the Service will meet your
          requirements.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Governing Law</h2>

        <p dir="ltr">
          In the event of a dispute, the Parties agree to work cooperatively to seek
          resolution, including engaging in mediation prior to initiating litigation. This
          Agreement shall be governed by and construed and enforced in accordance with the
          law of the State of Maine, without regard to conflict of law principles.
          Exclusive venue for any dispute arising between the Parties shall be in
          Portland, Maine.
        </p>
      </div>
    </article>
  </>
)

export default IndexPage
